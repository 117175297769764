<template>
    <div id="page-tv-households-kaltura-search-results">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="{ name: 'households-kaltura' }">Back to Kaltura</router-link>

                    <h1 class="heading">Search results</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <app-table
                        class="search-results-table"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :cols="cols"
                        :rows="rows"

                        :loading="loading"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        appTable,
        appPagination,
    },

    data() {
        return {
            value: '',

            filter: {
                sort: {
                    field: 'Name',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'Name',      title: 'Name',         },
                { field: 'Email',     title: 'E-mail',       },
                { field: 'CreatedAt', title: 'Signup (NZT)', },
            ],

            cols: [
                { key: 'name',           title: 'Name',           sort: { field: 'Name' },      highlight: true, },
                { key: 'email',          title: 'E-mail',         sort: { field: 'Email' },                      },
                { key: 'household-uuid', title: 'HouseHold UUID',                                                },
                { key: 'created-at',     title: 'Signup (NZT)',   sort: { field: 'CreatedAt' },                  },
            ],
            rows: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },
        }
    },

    computed: {
        ...mapGetters([
            'tv_households_search_results',
        ]),
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.value = this.$route.query.search ? this.$route.query.search : ''

            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            const { PageInfo, SearchResults } = this.tv_households_search_results

            if (PageInfo && SearchResults) {
                this.renderData(PageInfo, SearchResults)

                this.$store.commit('clearTVHouseholdsSearchResults')
            } else {
                this.getSearchResults()
            }
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getSearchResults()
        },

        onPageChange(page) {
            this.getSearchResults(page)
        },

        getSearchResults(page) {
            this.loading = true

            const filter = {
                ...this.filter,
            }

            let params = {
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':     filter.sort.field,
                'SearchOptions.SortDesc':   filter.sort.order === 'desc' ? 1 : 0,
            }

            const word = this.value.trim()

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                }
            }

            this.$store.dispatch('getTVHouseholdsSearchResults', { params })
                .then(({ PageInfo, SearchResults }) => {
                    this.renderData(PageInfo, SearchResults)

                    this.loading = false
                })
                .catch(error => {
                    console.log(error)

                    this.rows = []

                    this.loading = false
                })
        },

        renderData(PageInfo, SearchResults) {
            console.log(PageInfo)
            console.log(SearchResults)

            this.rows = SearchResults.map( result => ({
                uuid: result.UUID,
                name: result.Name,
                email: result.Email,
                'household-uuid': result.HouseholdUUID,
                'created-at': formatDateNano(result.CreatedAt),
            }))

            const { DisplayPageList, PageNumber, TotalItemCount } = PageInfo

            this.pagination.page = PageNumber
            this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

            if (this.$route.params.page != this.pagination.page
                && !(
                    this.$route.params.page === undefined
                    && this.pagination.page === 1
                )
            ) {
                this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
            }
        },
    },
}
</script>

<style lang="scss">
#page-tv-households-kaltura-search-results {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        margin-bottom: 48px;
    }

    .search-results-table {
        @include table-cols-width((250px, 250px, 300px, 200px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    #page-tv-households-kaltura-search-results {
        .search-results-table {
            @include table-cols-width((200px, 200px, 300px, 150px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    #page-tv-households-kaltura-search-results {
        margin: 24px 0 64px;

        .btn-back-to {
            margin-bottom: 8px;
        }

        h1 {
            margin-bottom: 15px;
        }

        .search-results-table {
            @include table-cols-width-mobile((90px, 160px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>